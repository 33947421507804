import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
// import { IoPeopleOutline } from "react-icons/io5";
// import { MdCalendarMonth, MdStarOutline } from "react-icons/md";
import Hero from "../components/hero";
import Layout from "../components/layout";
import BackgroundPattern2 from "../images/Behind photo logo.svg";
import useOnScreen from "../components/use-on-screen";
import CountUp from "react-countup";
import { BiTimeFive } from "react-icons/bi";

const AboutUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			pageData: wpPage(slug: { eq: "about-us" }) {
				pageFlexibleContent {
					fieldGroupName
					pageFlexibleContent {
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner {
							fieldGroupName
							innerPageBannerHeading
							innerPageBannerSubHeading
							innerPageLink {
								target
								title
								url
							}
							innerPageBannerImage {
								altText
								sourceUrl
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection {
							fieldGroupName
							twoColumnContent
							twoColumnFlip
							alignImageMiddle
							twoColumnButton {
								target
								title
								url
							}
							twoColumnImage {
								altText
								localFile {
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData
									}
								}
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_BasicContentSection {
							basicContent
							fieldGroupName
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_NumberCounterSection {
							fieldGroupName
							numberCounters {
								aboutPageCounterText
								pageCounterNumber
								pageCounterIcon {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData
										}
									}
								}
							}
						}
					}
				}
			}
			heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			mattImg: wpMediaItem(title: { eq: "Matt" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			mattImg2: wpMediaItem(title: { eq: "Matt BW" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			reviews {
				reviewCount
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const ref1 = useRef();
	const onScreen1 = useOnScreen(ref1, "0px");
	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
		],
	};

	const {
		pageData: {
			pageFlexibleContent: { pageFlexibleContent },
		},
	} = data;
	let j = 0;

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="About Us | Cranfield IT Solutions: Computer Repair & Tech Support Sussex"
				description="Cranfield IT Solutions is a technology solutions provider that has been serving the needs of businesses in East Sussex to London since its establishment in 2020."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us`,
					title: "About Us | Cranfield IT Solutions: Computer Repair & Tech Support Sussex",
					description:
						"Cranfield IT Solutions is a technology solutions provider that has been serving the needs of businesses in East Sussex to London since its establishment in 2020.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>

				{pageFlexibleContent.length > 0 &&
					pageFlexibleContent.map((pageInfo, i) => {
						if (
							pageInfo.fieldGroupName ==
							"Page_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection"
						) {
							j = j + 1;
						}
						return (
							<>
								{pageInfo.fieldGroupName ==
									"Page_Pageflexiblecontent_PageFlexibleContent_BasicContentSection" && (
									<Container className="pb-5">
										{" "}
										<Row>
											<Col
												dangerouslySetInnerHTML={{
													__html: pageInfo.basicContent,
												}}
											/>
										</Row>
									</Container>
								)}
								{pageInfo.fieldGroupName ==
									"Page_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner" && (
									<Hero
										title={
											<span
												dangerouslySetInnerHTML={{
													__html: pageInfo.innerPageBannerHeading,
												}}
											></span>

										}
										subHeading={pageInfo.innerPageBannerSubHeading}
										buttonTarget={pageInfo.innerPageLink?.target}
										buttonLink={pageInfo.innerPageLink?.url}
										buttonTitle={pageInfo.innerPageLink?.title}
										bannerImage={pageInfo.innerPageBannerImage.sourceUrl}
										hideButton={!pageInfo.innerPageLink ?? true}
										key={i}
									/>
								)}
								{pageInfo.fieldGroupName ==
									"Page_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection" &&
									j == 1 && (
										<section className="position-relative" key={i}>
											<Container className=" position-relative py-5 pb-lg-7">
												<div
													style={{ zIndex: 1 }}
													className="bg-black position-absolute d-none d-lg-block ps-5 ps-xl-8 text-end top-0 end-0"
												>
													<img alt="" className="" src={BackgroundPattern2} />
												</div>
												<div
													style={{ zIndex: 1 }}
													className="bg-black position-absolute d-md-none w-80 top-0 ps-5 text-end end-0"
												>
													<img
														alt=""
														style={{ maxHeight: "15rem" }}
														src={BackgroundPattern2}
													/>
												</div>
												<div
													style={{ zIndex: 1 }}
													className="bg-black position-absolute d-none d-md-block d-lg-none w-80 top-0 ps-5 text-end end-0"
												>
													<img
														alt=""
														style={{ maxHeight: "25rem" }}
														src={BackgroundPattern2}
													/>
												</div>

												<Row
													style={{ zIndex: 3 }}
													className={`g-3 g-xl-7 position-relative ${
														pageInfo.alignImageMiddle === true
															? "align-items-center"
															: ""
													} ${
														pageInfo.twoColumnFlip === "Flip Yes"
															? "flex-row-reverse"
															: ""
													}`}
												>
													<Col lg={{ span: 6 }}>
														<div
															dangerouslySetInnerHTML={{
																__html: pageInfo.twoColumnContent,
															}}
														/>
													</Col>
													<Col
														xs={{ order: "first" }}
														lg={{ span: 6, order: "last" }}
													>
														<div
															className="position-relative d-none d-lg-block w-90 w-xl-70 start-0  "
															style={{
																overflow: "hidden",
																top:
																	pageInfo.alignImageMiddle === true
																		? ""
																		: "-4rem",
																borderRadius: "8px",
															}}
														>
															<GatsbyImage
																image={
																	pageInfo.twoColumnImage.localFile
																		.childImageSharp.gatsbyImageData
																}
																alt={pageInfo.twoColumnImage.altText}
															/>
														</div>


														<div
															className="position-relative  d-lg-none mx-md-auto w-80 w-md-60   "
															style={{
																overflow: "hidden",

																borderRadius: "8px",
															}}
														>
															<GatsbyImage
																className=""
																image={
																	data.mattImg?.localFile.childImageSharp
																		.gatsbyImageData
																}
																alt={data.mattImg?.altText}
															/>
														</div>
													</Col>
												</Row>
											</Container>
										</section>

									)}
								{pageInfo.fieldGroupName ==
									"Page_Pageflexiblecontent_PageFlexibleContent_NumberCounterSection" && (
									<section className="pb-5 pb-lg-9" key={i}>
										<Container ref={ref1}>
											<Row>
												{pageInfo.numberCounters.length > 0 &&
													pageInfo.numberCounters.map((item, index) => (
														<Col
															xs={true}
															md={4}
															className="text-center"
															key={index}
														>
															<p className="display-4 roboto-regular pb-0 mb-0">
																{onScreen1 && (
																	<CountUp
																		start={0}
																		end={item.pageCounterNumber}
																	/>
																)}
															</p>
															<img
																alt={item.pageCounterIcon.altText}
																className="display-4 mb-3 text-primary"
																style={{ maxWidth: "56px" }}
																src={item.pageCounterIcon.sourceUrl}
															/>
															<p className="d-none d-md-block">
																{item.aboutPageCounterText}
															</p>
															<p
																style={{ fontSize: "90%" }}
																className="d-md-none"
															>
																{item.aboutPageCounterText}
															</p>
														</Col>
													))}

												{/* <Col xs={3} md={4} className="text-center">
                            <p className="display-4 roboto-regular pb-0 mb-0">
                              {" "}
                              {onScreen1 && <CountUp start={0} end={31} />}
                            </p>
                            <IoPeopleOutline className="display-4 mb-3 text-primary" />
                            <p className="d-none d-md-block">Clients</p>
                            <p
                              style={{ fontSize: "90%" }}
                              className="d-md-none"
                            >
                              Clients
                            </p>
                          </Col>
                          <Col xs={true} md={4} className="text-center">
                            <p className="display-4 roboto-regular pb-0 mb-0">
                              {onScreen1 && (
                                <CountUp
                                  start={0}
                                  end={data.reviews.reviewCount}
                                />
                              )}
                            </p>
                            <MdStarOutline className="display-4 mb-3 text-primary" />
                            <p className="d-none d-md-block">
                              Five star reviews
                            </p>
                            <p
                              style={{ fontSize: "90%" }}
                              className="d-md-none"
                            >
                              Five star reviews
                            </p>
                          </Col> */}
											</Row>
										</Container>
									</section>
								)}
								{pageInfo.fieldGroupName ==
									"Page_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection" &&
									j == 2 && (
										<section className=" position-relative" key={i}>
											<Container className=" position-relative py-5 pb-lg-7 ">
												<div
													style={{ zIndex: 1, left: "6rem" }}
													className="bg-black position-absolute d-none d-lg-block ps-5 ps-xl-8 text-end top-0 "
												>
													<img alt="" className="" src={BackgroundPattern2} />
												</div>
												<div
													style={{ zIndex: 1 }}
													className="bg-black position-absolute d-md-none w-80 top-0  text-end start-0"
												>
													<img
														alt=""
														style={{ maxHeight: "15rem" }}
														src={BackgroundPattern2}
													/>
												</div>
												<div
													style={{ zIndex: 1 }}
													className="bg-black position-absolute d-none d-md-inline-block d-lg-none w-80 w-md-auto top-0  text-start start-0"
												>
													<img
														alt=""
														style={{ maxHeight: "25rem" }}
														src={BackgroundPattern2}
													/>
												</div>

												<Row
													style={{ zIndex: 3 }}
													className={`g-3 g-xl-7 position-relative ${
														pageInfo.alignImageMiddle === true
															? "align-items-center"
															: ""
													} ${
														pageInfo.twoColumnFlip === "Flip Yes"
															? "flex-row-reverse"
															: ""
													}`}
												>
													<Col lg={{ span: 6 }}>
														<div
															dangerouslySetInnerHTML={{
																__html: pageInfo.twoColumnContent,
															}}
														/>
													</Col>
													<Col
														xs={{ order: "first" }}
														lg={{ span: 6, order: "last" }}
													>
														<div
															className="position-relative d-none d-lg-block w-90 w-xl-70 start-0  "
															style={{
																overflow: "hidden",
																top:
																	pageInfo.alignImageMiddle === true
																		? ""
																		: "-4rem",
																borderRadius: "8px",
															}}
														>
															<GatsbyImage
																image={
																	pageInfo.twoColumnImage.localFile
																		.childImageSharp.gatsbyImageData
																}
																alt={pageInfo.twoColumnImage.altText}
															/>
														</div>


														<div
															className="position-relative  d-lg-none ms-auto mx-md-auto w-80 w-md-60   "
															style={{
																overflow: "hidden",

																borderRadius: "8px",
															}}
														>
															<GatsbyImage
																image={
																	pageInfo.twoColumnImage.localFile
																		.childImageSharp.gatsbyImageData
																}
																alt={pageInfo.twoColumnImage.altText}
															/>
														</div>
													</Col>
												</Row>
											</Container>
										</section>
									)}
							</>
						);
					})}

			</Layout>
		</>
	);
};

export default AboutUsPage;
